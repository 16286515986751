import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"

import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-duotone-svg-icons"

const HeroRotationBlock = ({
  block,
  forceLoadImages = false,
  firstComponent = false,
}) => {
  const accentColor =
    block.accent_color?.length > 0 ? block.accent_color : "bg-primary"

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <FontAwesomeIcon icon={faArrowRight} size="1x" />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <FontAwesomeIcon icon={faArrowLeft} size="1x" />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    mobileFirst: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <button page={i + 1}>
        {i + 1}/{block.hero_rotation_items?.length}
      </button>
    ),
    asNavFor: nav2,
  }

  const settings2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    mobileFirst: true,
    infinite: true,
    asNavFor: nav1,
  }

  return (
    <BlockWrapper
      block={block}
      showHeadline="false"
      blockPadding={`${firstComponent ? "pt-0" : "pt-block"} pb-block`}
    >
      <div
        className={`flex flex-row flex-wrap ${
          block.alternate_design ? "-mx-half-grid" : "-mx-grid"
        } `}
      >
        {block.alternate_design && (
          <div className="hidden w-1/4 lg:block px-half-grid">
            <div className={`flex w-full h-full ${accentColor}`}></div>
          </div>
        )}
        <div
          className={`${
            block.alternate_design
              ? "w-full lg:w-3/4 px-half-grid"
              : "w-full px-grid"
          } `}
        >
          <Slider
            ref={(slider1) => setNav1(slider1)}
            className="relative"
            {...settings}
          >
            {block.hero_rotation_items?.length > 0 &&
              block.hero_rotation_items.map((item, index) => (
                <React.Fragment key={index}>
                  <Image
                    className="object-cover w-full h-auto aspect-video"
                    image={item.image}
                    forceLoad={forceLoadImages && index === 0 ? true : false}
                  />
                </React.Fragment>
              ))}
          </Slider>
        </div>
        {block.alternate_design && (
          <div className="hidden w-1/4 lg:block px-half-grid"></div>
        )}
        <div
          className={`w-full ${
            block.alternate_design
              ? "lg:w-3/4 px-half-grid"
              : "lg:w-full px-grid"
          } `}
        >
          <Slider
            ref={(slider2) => setNav2(slider2)}
            className="relative"
            {...settings2}
          >
            {block.hero_rotation_items?.length > 0 &&
              block.hero_rotation_items.map((item, index) => (
                <div key={index}>
                  <div className="flex flex-row flex-wrap -mx-grid">
                    <div
                      className={`w-full ${
                        block.alternate_design ? "lg:w-full" : " lg:w-1/2"
                      } pt-10 px-grid`}
                    >
                      <HeadlineEnhanced
                        className=""
                        kicker={item.kicker}
                        headlineLevel={
                          firstComponent && index === 0 ? "h1" : "h2"
                        }
                        headline={item.headline}
                        headlineClass=""
                      />
                    </div>
                    <div
                      className={`w-full ${
                        block.alternate_design ? "lg:w-full" : " lg:w-1/2"
                      } pt-10 px-grid`}
                    >
                      {block.alternate_design === false && (
                        <div className="hidden w-48 h-1 mb-12 lg:block bg-primary"></div>
                      )}
                      {item.text?.length > 0 && <Richtext text={item.text} />}
                      {item.link_text?.length > 0 && (
                        <Link
                          className="mt-10 btn btn-primary"
                          link={item.link}
                        >
                          {item.link_text}
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            size="1x"
                            className="inline-block ml-2.5 md:-mr-1.5"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default HeroRotationBlock
